<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Edit Items</h3>

	<p>To edit an item in the framework/document:
		<ul>
			<li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> with a System or Framework Admin or Editor access (Shared Host plan and Dedicated Host plan subscribers only - see <span v-html="link('admin', 'Admin Functions')"></span>)</li>
			<li>After choosing a framework, use the EDIT FRAMEWORK icon <nobr><v-icon small>fa-solid fa-pen-to-square</v-icon></nobr> to enable edit mode. The edit icon will turn black and have a circle to indicate editing is enabled.</li>
			<li>From the tree view, select the item you want to edit in the framework tree to reveal the item card on the right.</li>
			<li>Select the <strong>EDIT</strong> <nobr><v-icon small>fa-solid fa-pen-to-square</v-icon></nobr> icon from the item card.
				<ul>
					<li><strong>NOTE:</strong> Refer to the <span v-html="link('add_item', 'Add Item')"></span> help for details on the metadata you can modify on the card that appears.</li>
				</ul>
			</li>
			<li>Click <strong>SAVE CHANGES</strong> to save your edits; the button will become DONE EDITING, which you can click to close the card. Or click CANCEL to discard the edits
				<ul>
					<li><strong>NOTE:</strong> If you have not made any changes you will only see a DONE EDITING button.</li>
				</ul>
			</li>
			<li><strong>NOTE:</strong> The full statement is often a commonly used field by teachers, student information systems, learning management systems, etc. For this reason, adding HTML coding or other formatting to the text of the full statement is not recommended. Refer to the Add New Items and Supplemental Information user guides for additional support.</li>
		</ul>
	</p>
	

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	